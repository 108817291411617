@import "../../mixin";

.login-container-row {
  @include grid(1fr 1fr);
  height: calc(100vw * 0.463);
  @include for_breakpoint(LargeTablet) {
    height: calc(100vw * 0.7);
  }
  @include for_breakpoint(tablet) {
    @include grid(1fr);
    padding: 30px;
    background-color: rgba(60, 148, 198, 1);
    background-image: url("../../Assets/Images/vector.png");
    height: fit-content;
    background-blend-mode: multiply;
  }
  > div {
    height: auto;
  }
  > div:nth-child(1) {
    @include flex($dir: column, $align: center);
    text-align: center;
    max-width: 450px;
    margin: auto;
    @include for_breakpoint(tablet) {
    }
    > h1 {
      font-family: "Rofane";
      font-size: 40px;
      color: rgba(60, 148, 198, 1);
      @include for_breakpoint(tablet) {
        color: white;
      }
    }
    > p:nth-child(2) {
      font-family: "cabinet_grostek";
      font-size: 16px;
      color: rgba(1, 18, 34, 1);
      margin-bottom: -0.5rem;
      @include for_breakpoint(tablet) {
        color: white;
      }
    }
    > h3 {
      font-family: "Poppfine";
      font-size: 62px;
      color: rgba(1, 18, 34, 1);
      margin-top: 20px;
      letter-spacing: 2px;
      margin-bottom: 0px;
      @include for_breakpoint(tablet) {
        color: white;
      }
    }
    > p:nth-child(4) {
      font-family: "Poppins_Regular";
      color: rgba(28, 28, 28, 1);
      font-size: 14px;
      margin-top: -15px;
      @include for_breakpoint(tablet) {
        color: white;
      }
    }
  }
  > div:nth-child(2) {
    @include for_breakpoint(tablet) {
      display: none;
    }
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
    padding: 40px 20px;
    @include flex();
    background-color: rgba(60, 148, 198, 1);
    border-bottom-left-radius: 200px;
    > img:nth-child(1) {
      width: 100%;
      opacity: 0.08;
      position: absolute;
      top: 0;
    }
    > img:nth-child(2) {
      overflow: hidden;
      z-index: 11;
      width: 80%;
      position: relative;
      top: 0;
    }
  }
  form {
    @include for_breakpoint(tablet) {
      width: 100%;
    }
    margin-top: 0px;
    > div {
      margin-bottom: 20px;
      > label {
        float: left;
        font-family: "Poppins_Regular";
        color: rgba(28, 28, 28, 1);
        font-size: 14px;
        @include for_breakpoint(tablet) {
          color: white;
        }
      }
      > div {
        margin-top: 5px;
        position: relative;
        top: 0;
        > svg {
          position: absolute;
          left: 15px;
          font-size: 20px;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
        }
        input {
          border-radius: 5px;
          width: calc(100vw * 0.25);
          padding: 10px 10px 10px 40px;
          font-family: "Poppins_Medium";
          font-size: 14px;
          color: rgba(28, 28, 28, 1);
          outline: none;
          border: none;
          background-color: rgba(242, 244, 250, 1);
          height: 54px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          &::placeholder {
            font-family: "Poppins_Medium";
            color: rgba(28, 28, 28, 0.2);
            opacity: 1; /* Firefox */
          }

          &::-ms-input-placeholder {
            /* Edge 12 -18 */
            font-family: "Poppins_Medium";
            color: rgba(28, 28, 28, 0.2);
          }
          @include for_breakpoint(LargeTablet) {
            width: calc(100vw * 0.38);
          }
          @include for_breakpoint(tablet) {
            width: 100%;
          }
        }
      }
      p {
        @include flex($justify: flex-end);
        font-family: "Poppins_Medium";
        font-size: 12px;
        color: rgba(28, 28, 28, 1);
        @include for_breakpoint(tablet) {
          color: white;
        }
      }
      > button {
        background-color: rgba(1, 18, 34, 1) !important;
        font-family: "Poppins_SemiBold";
        font-size: 18px;
        color: white;
        width: 324px;
        height: 54px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        @include for_breakpoint(mobile) {
          width: 260px;
        }
      }
    }
    > button:last-child {
      background-color: rgba(60, 148, 198, 1) !important;
      font-family: "Poppins_SemiBold";
      font-size: 12px;
      color: white;
      width: 125px;
      height: 35px;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
    }
  }
}

.gantt-chart-wrapper-container {
  max-width: 1200px;
  padding: 0px 20px;
  margin: 7rem auto 5rem auto;
  .header-view-media {
    @include flex($justify: flex-start);
  }
  > .header-settings-row {
    @include flex($justify: space-between);
    > div {
      > button {
        @include button(rgba(60, 148, 198, 1), $h: 44px, $w: 180px, $br: 4px);
        font-family: "Poppins_SemiBold" !important;
      }
    }
  }
  > .container {
    .headline__ {
      font-size: 24px;
      font-family: "Poppins_Bold";
      margin-bottom: 20px;
    }
    .mt-x5 {
      margin-top: 30px;
    }
    > .serial__code__scanner {
      margin: 0px 0px 30px 0px;
      > label {
        color: rgba(1, 18, 34, 1);
        font-family: "Poppins_SemiBold";
        font-size: 14px;
      }
      div {
        position: relative;
        top: 0;
        max-width: 25%;
        @include for_breakpoint(tablet) {
          max-width: 35%;
        }

        @include for_breakpoint(mediumTablet) {
          max-width: 45%;
        }

        @include for_breakpoint(mobile) {
          max-width: 100%;
        }
        > input {
          position: relative;
          top: 0;
          width: 100%;
          margin-top: 5px;
          border: none;
          outline: none;
          font-size: 12px;
          font-family: "Poppins_Medium";
          padding: 0px 15px;
          background-color: rgba(242, 244, 250, 1);
          height: 48px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
        }
        > img {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          z-index: 222;
        }
      }
    }
    > .mt-5 {
      margin-top: 30px;
    }
    > .media__grid__row {
      @include grid(repeat(4, 1fr));
      @include for_breakpoint(tablet) {
        @include grid(repeat(3, 1fr));
      }
      @include for_breakpoint(mediumTablet) {
        @include grid(repeat(2, 1fr));
      }
      > div {
        position: relative;
        top: 0;
        > .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 350px;
          background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.9),
            transparent
          );
          /*Semi-transparentblack*/
          @include flex($dir: column, $justify: flex-end, $align: center);
          border-radius: 15px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          @include for_breakpoint(mobile) {
            height: 200px;
          }
          > p {
            font-family: "Poppins_Medium";
            color: white;
            margin-bottom: 0rem !important;
            text-align: center;
            max-width: 140px;
            font-size: 14px;
            margin-bottom: -7px !important;
            &:last-child {
              margin-bottom: 30px !important;
            }
          }
        }
        > img {
          width: 100%;
          height: 350px;
          object-fit: cover;
          border-radius: 15px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          overflow-clip-margin: unset;
          overflow: hidden;
          @include for_breakpoint(mobile) {
            height: 200px;
          }
        }
      }
    }
    > .table-wrapper-pagination {
      border: 1px solid rgba(207, 212, 229, 1);
      padding: 30px 20px;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
    }
    > .dropdown__grid__row {
      @include grid(repeat(4, 1fr), $gap: 30px);
      margin-bottom: 40px;
      @include for_breakpoint(tablet) {
        @include grid(repeat(3, 1fr));
      }
      @include for_breakpoint(mobile) {
        @include grid(repeat(2, 1fr));
      }
      @include for_breakpoint(smallMobile) {
        @include grid(repeat(1, 1fr));
      }
    }
    > .tab-module-wrapper {
      @include grid(repeat(3, 1fr));
      max-width: fit-content;
      margin-left: 20px;

      @include for_breakpoint(tablet) {
        @include flex();
      }

      @include for_breakpoint(mobile) {
        overflow-x: scroll;
        justify-content: initial;
        align-items: initial;
        white-space: nowrap;
      }

      > button {
        @include button(
          rgba(214, 221, 242, 1),
          $h: 48px,
          $FF: Poppins_SemiBold,
          $br: 8px 8px 0px 0px
        );
        padding: 0px 20px !important;
        color: #1c1c1c !important;
      }
      .active-tab-module {
        @include button(
          rgba(60, 148, 198, 1),
          $h: 48px,
          $FF: Poppins_SemiBold,
          $br: 8px 8px 0px 0px
        );
      }
    }
    > .settings_container__fluid {
      padding: 30px 20px;
      border: 1px solid rgba(207, 212, 229, 1);
      height: auto;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      > .settings__grid__row {
        @include grid(repeat(3, 1fr), $gap: 30px);
        grid-row-gap: 20px;
        @include for_breakpoint(tablet) {
          @include grid(repeat(2, 1fr));
        }
        @include for_breakpoint(mobile) {
          @include grid(repeat(1, 1fr), $gap: 30px);
        }
        > div {
          > input {
            height: 48px;
            width: 100%;
            background-color: rgba(242, 244, 250, 1);
            padding: 3px 10px;
            font-family: "Poppins_SemiBold";
            font-size: 12px;
            border: none;
            outline: none;
            margin-top: 5px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }
      }
      > .mt__4x {
        margin-top: 20px;
      }
      > .mixed__sku__grid__row {
        @include grid(repeat(2, 1fr), $gap: 30px);
        grid-row-gap: 0px;
        max-width: 800px;
        @include for_breakpoint(mobile) {
          @include grid(repeat(1, 1fr));
        }
        > div {
          height: auto;
          > input {
            height: 48px;
            width: 100%;
            background-color: rgba(242, 244, 250, 1);
            padding: 3px 10px;
            font-family: "Poppins_SemiBold";
            font-size: 12px;
            border: none;
            outline: none;
            margin-top: 5px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }
      }
      > .submit__cta {
        @include flex();
        > button {
          margin-top: 30px;
          @include button(
            rgba(60, 148, 198, 1),
            $h: 48px,
            $FF: Poppins_SemiBold,
            $br: 4px
          );
          padding: 0px 50px;
        }
      }
    }
  }
}

.base-url-wrapper {
  margin-top: 20px;
  > div {
    > button {
      background-color: rgba(60, 148, 198, 1) !important;
      font-family: "Poppins_SemiBold";
      font-size: 16px;
      color: white !important;
      width: 100%;
      margin-top: 20px;
      height: 48px;
      border-radius: 5px;
    }
  }
}

.password-container {
  margin-top: 20px;
  > div {
    > input {
      height: 48px;
      background-color: rgb(242, 244, 250);
      width: 100%;
      outline: none;
      margin-top: 8px;
      padding: 10px 10px 10px 10px;
      font-family: "Poppins_Medium";
      font-size: 14px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
  }
}

.uri {
  font-family: "Poppins_Regular";
  font-size: 10px;
  margin-top: 5px;
  background-color: rgba(1, 18, 34, 1) !important;
  color: #cccc;
  display: inline-block;
  margin-bottom: 15px;
  padding: 1px 12px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.view__media__cta {
  @include button(
    rgba(60, 148, 198, 1),
    $w: 140px,
    $h: 38px,
    $br: 50px,
    $FF: "Poppins_Medium"
  );
  font-size: 12px !important;
}

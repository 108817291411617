.half-70-grid-row {
  @include grid(4fr 2fr, $gap: 30px);
  @include for_breakpoint(tablet) {
    @include grid(1fr);
  }
  .half-70-dropdown-grid-row {
    @include grid(1fr 1fr, $gap: 20px);
    @include for_breakpoint(tablet) {
      @include grid(1fr 1fr);
    }
    @include for_breakpoint(mobile) {
      @include grid(1fr);
    }
  }
  .half-70-input {
    @include grid(2fr 1fr);
    @include for_breakpoint(mediumTablet) {
      @include grid(1fr);
    }
    margin-top: 20px;
    > div {
      input {
        height: 48px;
        width: 100%;
        background-color: rgba(242, 244, 250, 1);
        padding: 3px 10px;
        font-family: "Poppins_SemiBold";
        font-size: 12px;
        border: none;
        outline: none;
        margin-top: 5px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }
    }
  }
  .manufacturing-container {
    margin-top: 30px;
  }
  > div:nth-child(2) {
    position: relative;
    top: 0;
    > .scrolled {
      top: 100px !important;
    }
    > .right-container-packaging-row {
      scrollbar-width: none;
      > div:nth-child(1) {
        > div:nth-child(4) {
          border-bottom: none !important;
        }
      }
      > div:nth-child(2) {
        margin-top: 30px;
        background-color: rgba(235, 248, 255, 1);
        padding: 15px 20px;
        border-radius: 16px;
        @include for_breakpoint(tablet) {
          margin-top: 0px;
        }
        @include for_breakpoint(mobile) {
          margin-top: 20px;
        }
        > p:nth-child(1) {
          color: rgba(1, 18, 34, 1);
          font-family: "Poppins_Medium";
          font-size: 14px;
          text-align: center;
        }
        > h4 {
          font-family: "Poppins_SemiBold";
          color: rgba(1, 18, 34, 1);
          font-size: 40px;
          text-align: center;
        }
        > button {
          @include button(
            $bg: rgba(252, 77, 77, 1),
            $w: 100%,
            $h: 44px,
            $br: 2px
          );
          margin-top: 5px;
          margin-bottom: 5px;
          font-family: "Poppins_SemiBold" !important;
        }
      }
      // position: fixed;
      // top: 160px;
      padding-right: 15px;
      transition: top 0.4s ease-in;
      -webkit-transition: top 0.4s ease-in;
      -moz-transition: top 0.4s ease-in;
      -ms-transition: top 0.4s ease-in;
      -o-transition: top 0.4s ease-in;
      // height: 400px;
      // overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      @include for_breakpoint(tablet) {
        position: initial;
        padding-right: 0px;
        height: auto;
        @include flex($dir: row, $gap: 30px, $align: flex-start);
        margin-top: 30px;
      }
      @include for_breakpoint(mobile) {
        @include flex($dir: column);
      }
      .box-row-cta-container {
        @include flex($dir: column);
        > button:nth-child(1) {
          @include button(
            $bg: rgba(60, 148, 198, 1),
            $w: 100%,
            $h: 44px,
            $br: 2px
          );
          margin-top: 5px;
          margin-bottom: 5px;
          font-family: "Poppins_SemiBold" !important;
        }
        > button:nth-child(2) {
          @include button(
            $bg: rgba(1, 18, 34, 1),
            $w: 100%,
            $h: 44px,
            $br: 2px
          );
          font-family: "Poppins_SemiBold" !important;
        }
      }
      > div:nth-child(1) {
        background-color: rgba(235, 248, 255, 1);
        padding: 15px 20px;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        height: auto;
        .total-number-box-container {
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(189, 226, 246, 1);
          padding: 10px 0px 10px 0px;
          @include grid(2fr 0.1fr 2fr, $gap: 10px);
          > div:nth-child(1),
          > div:nth-child(2) {
            color: rgba(1, 18, 34, 1);
            font-family: "Poppins_SemiBold";
            > p {
              color: rgba(1, 18, 34, 1);
              font-family: "Poppins_SemiBold";
              font-size: 13px;
            }
          }
          > div:nth-child(3) {
            > p {
              color: rgba(1, 18, 34, 1);
              font-family: "Poppins_Regular";
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.icon-picture {
  @include circle($w: 50px, $h: 70px, $br: 2px);
  object-fit: cover;
  object-position: top;
  overflow-clip-margin: unset;
  overflow: hidden;
}

.mrp-label-CTA {
  @include flex();
  margin-top: 23px;
  > button {
    @include button(
      $bg: rgba(75, 231, 110, 1),
      $color: white,
      $FF: "Poppins_SemiBold",
      $TT: capitalize,
      $h: 48px,
      $br: 2px,
      $w: 305px
    );
    @include for_breakpoint(mobile) {
      width: 200px !important;
      min-width: 200px !important;
    }
  }
}

.shake-button-container {
  @include flex($gap: 20px);
  margin: 10px 0px 10px 0px;
  button {
    font-size: 10px;
    font-family: "Poppins_SemiBold";
    border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
    -moz-border-radius: 2px !important;
    -ms-border-radius: 2px !important;
    -o-border-radius: 2px !important;
    padding: 6px 12px;
    position: relative;
    // animation-name: shakeAnim;
    // animation-duration: 1s;
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in;

    // @keyframes shakeAnim {
    //   0% {
    //     left: 0;
    //   }
    //   10% {
    //     left: -3px;
    //   }
    //   20% {
    //     left: 3px;
    //   }
    //   30% {
    //     left: -8px;
    //   }
    //   40% {
    //     left: 8px;
    //   }
    //   50% {
    //     left: -5px;
    //   }
    //   60% {
    //     left: 3px;
    //   }
    //   70% {
    //     left: 0;
    //   }
    // }
    color: white;
    // &:nth-child(1) {
    //   background-color: rgb(60, 148, 198) !important;
    // }
    // &:nth-child(2) {
    //   background-color: rgb(1, 18, 34) !important;
    // }
  }
}

.navbar {
  background-color: rgba(1, 18, 34, 1);
  position: fixed;
  top: 0;
  z-index: 444;
  width: 100%;
  > .navbar-container {
    @include flex($justify: space-between);
    max-width: 1160px;
    padding: 20px 20px 20px 20px;
    margin: auto;
    > div:nth-child(1) {
      @include flex($gap: 20px);
      > div {
        position: relative;
        top: 0;
        > .search-icon {
          position: absolute;
          top: 0;
          right: 0;
          height: 42px;
          background-color: white !important;
          border-radius: 2px !important;
          > span > svg {
            font-size: 1.5rem;
          }
          @include for_breakpoint(mobile) {
            display: none;
          }
        }
        > input {
          height: 42px;
          padding: 2px 80px 0px 10px;
          font-family: "Poppins_Medium";
          width: 549px;
          outline: none;
          border: none !important;
          color: white;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 2px;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          -ms-border-radius: 2px;
          -o-border-radius: 2px;
          @include for_breakpoint(tablet) {
            width: 300px;
          }
          @include for_breakpoint(mediumTablet) {
            width: 200px;
          }
          @include for_breakpoint(mobile) {
            display: none;
          }
        }
      }
    }
    > div:nth-child(2) {
      > div:nth-child(2) {
        > button {
          @include for_breakpoint(mediumTablet) {
            min-width: 20px !important;
          }
        }
      }
      @include flex();
      > div:nth-child(1) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        padding-right: 15px;
        @include flex(row);
        img {
          @include circle(40px, 40px);
          overflow-clip-margin: unset;
          overflow: hidden;
          object-fit: cover;
        }
        div {
          > p {
            color: white;
            font-family: "Poppins_Medium";
            font-size: 16px;
            margin-bottom: -2px;
            @include for_breakpoint(mobile) {
              font-size: 12px;
            }
          }
          > button {
            @include button($Fs: 12px);
            margin-left: -7px !important;
            @include for_breakpoint(mobile) {
              min-width: 20px !important;
            }
          }
        }
      }
    }
  }
}

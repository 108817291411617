table {
  th {
    font-family: "Poppins_Bold" !important;
    font-size: 12px !important;
    color: rgba(1, 18, 34, 0.8) !important;
    line-height: 1.3195 !important;
    text-align: center !important;
  }
  td {
    font-size: 11px !important;
    color: rgba(1, 18, 34, 1) !important;
    font-family: "Poppins_Medium" !important;
    text-align: center !important;
    &:nth-child(1) {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
    &:last-child {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }
}

._9w8d5 {
  font-family: "Poppins_Bold" !important;
  font-size: 12px !important;
  color: rgba(1, 18, 34, 0.8) !important;
}

.MuiTableCell-root {
  border-bottom: transparent !important;
}

.icon {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  font-family: "Poppins_Bold";
  gap: 5px;
  > img {
    @include circle(30px, 30px, 50%);
    object-fit: cover;
    overflow-clip-margin: unset;
    overflow: hidden;
  }
}

.MuiTableRow-head {
  background-color: rgba(225, 230, 245, 1) !important;
}

.MuiTableCell-head:nth-child(1) {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.MuiTableCell-head:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.table-container-wrapper {
  td {
    font-family: "Poppins_SemiBold" !important;
    font-size: 12px !important;
    border-bottom: 1px solid rgba(230, 233, 242, 1) !important;
  }
  &:last-child {
    td {
      border-bottom: none !important;
    }
  }
}

.table-wrapper-pagination {
  overflow-x: auto;
  white-space: nowrap;
}

.table-container-pagination {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.previous-box-cta {
  > button:nth-child(1) {
    @include button(
      $bg: rgba(75, 231, 110, 1),
      $br: 50px,
      $h: 26px,
      $FF: "Poppins_SemiBold",
      $w: 155px,
      $Fs: 12px
    );
    margin-bottom: 5px;
  }
  > button:nth-child(3) {
    @include button(
      $bg: rgba(1, 18, 34, 1),
      $br: 50px,
      $h: 26px,
      $FF: "Poppins_SemiBold",
      $w: 155px,
      $Fs: 12px
    );
  }
}

.green,
.orange,
.red,
.blue {
  font-family: "Poppins_Bold" !important;
}

.blue {
  color: #3b6790 !important;
}

.green {
  color: rgba(31, 197, 67, 1) !important;
}

.orange {
  color: rgba(249, 163, 24, 1) !important;
}

.red {
  color: rgba(252, 77, 77, 1) !important;
}

.filter-dropdown-cross {
  position: relative;
  top: 0;
  height: 48px;
}

.filter-icon {
  cursor: pointer;
  position: absolute;
  top: 65%;
  right: 44px;
  border: none;
  background-color: transparent;
  transform: translateY(-50%);
}

.label {
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  color: rgba(1, 18, 34, 1);
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

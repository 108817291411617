.heading-row {
  @include flex($justify: space-between);
  margin: 20px 0px 25px 0px;
  @include for_breakpoint(mediumTablet) {
    @include flex($dir: column, $align: flex-start);
    // margin: 0px 0px 25px 0px !important;
  }
  .left-row-heading-title {
    @include flex($gap: 15px, $align: center);
    > div {
      > svg {
        cursor: pointer;
        font-size: 25px;
      }
    }
    > .headline-content {
      h1 {
        font-family: "Poppins_Bold";
        text-transform: uppercase;
        color: rgba(1, 18, 34, 1);
        font-size: 30px;
        line-height: 1.1395;
        @include for_breakpoint(mobile) {
          font-size: 24px;
        }
      }
    }
  }
  .right-row-heading-title {
    @include flex($gap: 10px);
    @include for_breakpoint(mobile) {
      @include flex($dir: column, $align: flex-start);
    }
    // @include for_breakpoint(mediumTablet) {
    //   margin-top: 5px;
    // }
    > button:nth-child(1) {
      > span img {
        width: 22px;
      }
      @include button(
        $bg: rgba(46, 212, 126, 1),
        $FF: Poppins_SemiBold,
        $br: 2px,
        $h: 48px
      );
      padding: 10px 11px 10px 11px;
      // font-size: 16px;
    }
    > button:nth-child(2) {
      img {
        width: 22px;
      }
      @include button(
        $bg: rgba(1, 18, 34, 1),
        $FF: Poppins_SemiBold,
        $br: 2px,
        $h: 48px
      );
      padding: 10px 11px 10px 11px;
      // font-size: 16px;
    }
    > button:nth-child(3) {
      img {
        width: 22px;
      }
      @include button(
        $bg: rgba(1, 18, 34, 1),
        $FF: Poppins_SemiBold,
        $br: 2px,
        $h: 48px
      );
      padding: 10px 11px 10px 11px;
      // font-size: 16px;
    }
  }
}

.confirmation-box-row {
  > p {
    color: rgba(28, 28, 28, 1);
    font-family: "Poppins_SemiBold";
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  > .confirmation-container {
    margin-top: 20px;
    @include flex($dir: row, $align: center, $justify: center, $gap: 20px);
    > button {
      &:nth-child(1) {
        @include button(
          $bg: rgba(75, 231, 110, 1),
          $w: 148px,
          $h: 46px,
          $br: 2px
        );
        font-family: "Poppins_SemiBold" !important;
      }
      &:nth-child(2) {
        @include button(
          $bg: rgba(252, 77, 77, 1),
          $w: 148px,
          $h: 46px,
          $br: 2px
        );
        font-family: "Poppins_SemiBold" !important;
      }
    }
  }
  .closing-no {
    margin-top: 10px;
    @include flex($dir: row, $align: center, $justify: center, $gap: 20px);
    > button {
      @include button(
        $bg: rgba(60, 148, 198, 1),
        $w: 148px,
        $h: 46px,
        $br: 2px
      );
      font-family: "Poppins_SemiBold" !important;
      // background-color:  !important;
    }
  }
}

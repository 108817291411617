.header-cross-title {
  @include flex($dir: row, $align: center, $justify: space-between);
  > h2 {
    font-family: "Poppins_Bold" !important;
    font-size: 22px !important;
    text-align: center !important;
    white-space: pre-line;
    flex: 2.2;
    line-height: 1.3195 !important;
  }
}

.center-text {
  @include flex($dir: row, $align: center, $justify: space-between);
  border-bottom: 1px solid rgba(229, 229, 239, 0.3);
  padding-bottom: 15px;
  > h2 {
    font-family: "Poppins_Bold" !important;
    font-size: 22px !important;
    text-align: center !important;
    white-space: pre-line;
    flex: 2.2;
    line-height: 1.3195 !important;
  }
}

.left-align-text {
  @include flex($dir: row, $align: center, $justify: space-between);
  border-bottom: 1px solid rgba(229, 229, 239, 0.3);
  padding-bottom: 15px;
  > h2 {
    font-family: "Poppins_Bold" !important;
    font-size: 22px !important;
    text-align: left !important;
    white-space: pre-line;
    flex: 2.2;
    line-height: 1.3195 !important;
  }
}

.bg-cross {
  background-color: #1c1c1c;
  @include circle($w: 30px, $h: 30px, $br: 50%);
  @include flex($dir: row, $align: center, $justify: center);
  cursor: pointer;
}

.cross-icon-col {
  color: white;
  font-size: 22px !important;
}

@media screen and (max-width: 576px) {
  .MuiBox-root {
    width: 95% !important;
    margin: 20px 0px 20px 0px;
  }
  .modal-container {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .css-zyiran {
    max-width: 95%;
  }
}

.css-79ws1d-MuiModal-root {
  overflow: hidden !important;
}

.css-z3qzso {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 16px 35px 0px rgba(0, 0, 0, 0.1), 0px 64px 64px 0px rgba(0, 0, 0, 0.09),
    0px 143px 86px 0px rgba(0, 0, 0, 0.05),
    0px 255px 102px 0px rgba(0, 0, 0, 0.01),
    0px 398px 111px 0px rgba(0, 0, 0, 0) !important;
}

.confirmation-print {
  margin: 20px auto 20px auto;
  text-align: center;
  > p {
    color: rgb(28, 28, 28);
    font-family: "Poppins_SemiBold";
    font-size: 16px;
    // margin-top: 10px;
    text-align: center;
  }
}

.confirmation-button {
  @include flex($gap: 10px);
  margin-top: 10px;
  > button {
    width: 120px;
    font-family: "Poppins_SemiBold";
    font-size: 14px;
    border-radius: 2px;
    color: white;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    height: 48px;
    &:nth-child(1) {
      background-color: rgb(75, 231, 110) !important;
    }
    &:nth-child(2) {
      background-color: rgb(252, 77, 77) !important;
    }
  }
}

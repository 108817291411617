@import "mixin";
@import "./Pages/Styles/dataTable";
@import "./Pages/Styles/header";
@import "./Pages/Styles/heading";
@import "./Pages/Styles/dropdown";
@import "./Pages/Styles/login";
@import "./Pages/Styles/modal";
@import "./Pages/Styles/manufacturingPackage";
@import "./Pages/Styles/confirmationDialog";
@import "./Pages/Styles/previousBox";

* {
  margin: 0;
  padding: 0;
  transition: 0.5s ease-in-out;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  border: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

body,
html {
  overflow-x: hidden;
  padding-right: 0px !important;
  scrollbar-width: thin;
}

// Include Font Face
@font-face {
  font-family: Poppins_SemiBold;
  src: url("./Assets/Poppins-Fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins_Medium;
  src: url("./Assets/Poppins-Fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins_Bold;
  src: url("./Assets/Poppins-Fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins_Thin;
  src: url("./Assets/Poppins-Fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: Poppins_Regular;
  src: url("./Assets/Poppins-Fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: Brandon_Grostesque;
  src: url("./Assets/Poppins-Fonts/HvDTrial_Brandon_Grotesque_medium_italic-BF64a625c928c55.otf");
}

@font-face {
  font-family: Rofane;
  src: url("./Assets/Poppins-Fonts/Rofane-Italic-BF64e8630c2f189.ttf");
}

@font-face {
  font-family: cabinet_grostek;
  src: url("./Assets/Poppins-Fonts/CabinetGrotesk-Regular.ttf");
}

@font-face {
  font-family: Poppfine;
  src: url("./Assets/Poppins-Fonts/POPFINE\ Italic.ttf");
}

.MuiPagination-ul {
  > li {
    > button {
      font-family: "Poppins_Medium";
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
    }
  }
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
  font-family: "Brandon_Grostesque" !important;
  font-size: 18px !important;
  color: #1c1c1c !important;
}

.MuiMenu-paper {
  margin-top: 3rem !important;
  background-color: rgb(1, 18, 34) !important;
  > ul > li {
    font-family: "Poppins_Regular";
    font-size: 14px;
    color: white !important;
  }
}

.loading-container {
  @include flex();
}

.my__x5 {
  margin: 20px 0px;
}

.mt__x3 {
  margin-top: 5px;
}

._CZjuD {
  overflow-x: scroll !important;
}

._31ERP {
  width: 12px !important;
  // height: 12px !important;
}

// No Data
.no-data-gif {
  @include flex($dir: column);
  > img {
    width: 340px;
  }
  > p {
    margin-top: -60px;
    margin-bottom: 10px;
    font-size: 22px;
    color: rgb(1, 18, 34);
    font-family: "Brandon_Grostesque";
  }
}

button {
  transition: 0.3s ease-in-out !important;
  -webkit-transition: 0.3s ease-in-out !important;
  -moz-transition: 0.3s ease-in-out !important;
  -ms-transition: 0.3s ease-in-out !important;
  -o-transition: 0.3s ease-in-out !important;
}

button:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0) !important;
  -webkit-transform: matrix(0.95, 0, 0, 0.95, 0, 0) !important;
  -moz-transform: matrix(0.95, 0, 0, 0.95, 0, 0) !important;
  -ms-transform: matrix(0.95, 0, 0, 0.95, 0, 0) !important;
  -o-transform: matrix(0.95, 0, 0, 0.95, 0, 0) !important;
}

.modal-container {
  overflow: hidden !important;
}

.bg__settings {
  @include button(rgba(1, 18, 34, 1), $h: 42px, $w: 42px, $br: 4px);
}

// Sidebar CSS Starts here
.sidebar__container {
  position: fixed;
  top: 0;
  width: 250px;
  background-color: rgb(1, 18, 34);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  left: -450px;
  height: 100vh;
  z-index: 9999;
  border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -ms-border-radius: 0px 0px 0px 0px;
  -o-border-radius: 0px 0px 0px 0px;
  padding-top: 30px;
  > h1 {
    font-family: "Rofane";
    font-size: 40px;
    color: rgb(60, 148, 198);
    text-align: center;
    margin-bottom: 30px;
  }
  > button {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: absolute;
    top: 30px;
    right: -10px;
    background-color: white !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
    > svg {
      color: #1c1c1c;
    }
  }
  > .sidebar__container__fluid {
    position: relative;
    top: 0;
    > .sidebar__row {
      margin: 10px 20px;
      @include grid(20px 1fr);
      text-decoration: none;
      padding: 8px 15px;
      border-radius: 50px;
      > div {
        @include flex($justify: flex-start);
        color: white;
        font-family: "Poppins_Regular";
        font-size: 16px;
        > svg {
          color: white;
        }
      }
    }
    > .active__sidebar {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.sidebar__open {
  left: 0;
}

.date-picker {
  border: none;
  min-height: 48px;
  border-radius: 4px;
  padding: 2px 8px;
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  outline: none;
  background-color: rgba(242, 244, 250, 1);
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
}

.menu__items {
  font-size: 13px !important;
}

// Sidebar CSS Ends here

.left__align {
  text-align: left !important;
  > input {
    width: 100%;
    height: 48px;
    font-family: "Poppins_SemiBold";
    font-size: 12px;
    background-color: rgba(242, 244, 250, 1);
    outline: none;
    border: none;
    padding: 0px 15px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
}

.upload__image__cta {
  background-color: rgb(60, 148, 198) !important;
  font-family: "Poppins_SemiBold" !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  border-radius: 30px !important;
  color: white !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}

.img__col {
  width: 45px;
  height: auto;
  overflow-clip-margin: unset;
  overflow: hidden;
  object-fit: cover;
}

.submit__cta {
  @include flex();
  margin-top: 30px;
  > button {
    background-color: rgb(60, 148, 198) !important;
    font-family: "Poppins_SemiBold" !important;
    padding: 0px 40px !important;
    height: 48px;
    width: 140px;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    color: white !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
  }
}
